import { delay } from '~/shared/tools/delay';

const eventSourceReconnectTimeout = 3000;

let sseApi:EventSource;

export const initEventSourceApi = (clId:string):EventSource => {
  if (window.EventSource && clId) {
    sseApi?.close();
    // TODO: check reconnect after 401 error by rewoke token
    sseApi = new EventSource(`/events/${clId}`, { withCredentials: true });
    sseApi.addEventListener('error', async () => {
      if (sseApi.readyState !== EventSource.CONNECTING) {
        sseApi.close();
        await delay(eventSourceReconnectTimeout);
        initEventSourceApi(clId);
      }
    });
  }

  return sseApi;
};
