import { useCallback } from 'react';

import { OfferStatuses } from '%/data/offer';
import { IOffer, IOfferData } from '%/entities/offer';
import { useApi } from '~/core/api';

export const useOfferActions = () => {
  const { fetchApi } = useApi();

  const getOffers = useCallback(() => fetchApi('/api/offers', { notifyWhenFailed: true }), []);
  const getOffer = useCallback((id:string) => fetchApi(`/api/offers/${id}`, { notifyWhenFailed: true }), []);
  const getOfferDocuments = useCallback((id:string) => fetchApi(`/api/offers/${id}/documents`, { notifyWhenFailed: true }), []);
  const getOfferShortlink = useCallback((id:string) => fetchApi(`/api/offers/${id}/sl`, { notifyWhenFailed: true }), []);
  const getOfferRecognitionTaskLink = useCallback((id:string) => fetchApi(`/api/offers/${id}/recognition-link`, { notifyWhenFailed: true }), []);
  const createOffer = useCallback((values:Partial<IOffer>, notifyWhenFailed = true) => fetchApi('/api/offers', {
    method: 'post',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const updateOffer = useCallback((values:IOffer, notifyWhenFailed = true) => fetchApi(`/api/offers/${values.id}`, {
    method: 'put',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const updateOfferStatus = useCallback((id:string, status:keyof typeof OfferStatuses) => fetchApi(`/api/offers/${id}/status`, {
    method: 'put',
    json: JSON.stringify({ status }),
    notifyWhenFailed: true
  }), []);
  const uploadOfferFiles = useCallback((id:string, values:IOfferData) => fetchApi(`/api/offers/${id}/files`, {
    method: 'put',
    json: JSON.stringify(values),
    notifyWhenFailed: true
  }), []);

  return {
    createOffer,
    getOffer,
    getOffers,
    getOfferDocuments,
    getOfferShortlink,
    getOfferRecognitionTaskLink,
    updateOffer,
    updateOfferStatus,
    uploadOfferFiles
  };
};