import { useCallback } from 'react';

import { messages } from '%/data/messages';
import { IError } from '%/entities/error';
import { forceLogout } from '~/shared/components/auth/tools';
import { useNotification } from '~/shared/components/notification';
import { getToken } from '~/shared/hooks/use-user';

type IExtendedParams = {
  json?: BodyInit
  notifyWhenFailed?: boolean
}

type IApiProps = {
  fetchApi: (url:string, params?:RequestInit & IExtendedParams) => Promise<any>
}

export const useApi = ():IApiProps => {
  const { addNotification } = useNotification();

  const fetchApi = useCallback(async (url:string, { notifyWhenFailed, ...params }:RequestInit & IExtendedParams = {}) => {
    const token = getToken();
    const response = await fetch(url, {
      ...params,
      headers: {
        ...params.headers,
        ...(params.json ? { 'Content-Type': 'application/json' } : {}),
        ...(token ? { 'Authorization': `Bearer ${token}` } : {})
      },
      body: params.json || params.body
    });
    const { status, headers } = response;

    if (![200, 201].includes(status)) {
      let resp:IError = { errorStatus: status };
      if (status === 401) {
        forceLogout();
        resp.message = 'Ваша сессия истекла';
      }
      if (status === 504) {
        resp.message = messages.somethingWentWrong;
      }

      try {
        const responseBody = headers.get('content-type')?.includes('json') ?
          await response.json() :
          { errorText: await response.text() };
        resp = { ...resp, ...responseBody };
      } catch (err) {
        console.error('try parse response error: ', err);
      }

      notifyWhenFailed && addNotification({
        text: resp.message || resp.errorText || JSON.stringify(resp),
        type: 'warning',
        autohide: true,
        duration: 5
      });
      return resp;
    }

    return response.json();
  }, [addNotification]);

  return ({ fetchApi });
};