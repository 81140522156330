import { useCallback } from 'react';

import { UserRoles } from '%/data/user-roles';
import { IAuthRequestBody } from '%/entities/auth';
import { IUser, IUserPassword } from '%/entities/user';
import { useApi } from '~/core/api';

export const useUserActions = () => {
  const { fetchApi } = useApi();

  const getUsers = useCallback((role?:keyof typeof UserRoles) => fetchApi(
    `/api/users${role ? `?role=${role}` : ''}`,
    { notifyWhenFailed: true }
  ), []);
  const getUsersByRole = (role:keyof typeof UserRoles) => () => getUsers(role);
  const getCurrentUser = useCallback(() => fetchApi('/api/users/current', { notifyWhenFailed: true }), []);
  const createUser = useCallback((values:Partial<IUser>, notifyWhenFailed = true) => fetchApi('/api/users', {
    method: 'post',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const updateUser = useCallback((values:IUser, notifyWhenFailed = true) => fetchApi(`/api/users/${values.id}`, {
    method: 'put',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const setCurrentUserPassword = useCallback((values:IUserPassword, notifyWhenFailed = true) => fetchApi(`/api/users/current-password`, {
    method: 'post',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const updateCurrentUserPassword = useCallback((values:IUserPassword, notifyWhenFailed = true) => fetchApi(`/api/users/current-password`, {
    method: 'put',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const deleteUser = useCallback((id:string) => fetchApi(`/api/users/${id}`, {
    method: 'delete',
    notifyWhenFailed: true
  }), []);

  const login = useCallback(async (data:IAuthRequestBody) => await fetchApi('/api/login', {
    method: 'post',
    json: JSON.stringify(data)
  }), []);
  const logout = useCallback(() => fetchApi('/api/logout', { method: 'post' }), []);

  return {
    createUser,
    getUsers,
    getUsersByRole,
    updateUser,
    deleteUser,

    login,
    logout,

    getCurrentUser,
    updateCurrentUserPassword,
    setCurrentUserPassword
  };
};