import { useCallback } from 'react';

import { ICompany } from '%/entities/company';
import { useApi } from '~/core/api';

export const useCompanyActions = () => {
  const { fetchApi } = useApi();

  const getCompanies = useCallback(() => fetchApi('/api/companies', { notifyWhenFailed: true }), []);
  const getCompany = useCallback((id:string) => fetchApi(`/api/companies/${id}`, { notifyWhenFailed: true }), []);
  const createCompany = useCallback((values:Partial<ICompany>, notifyWhenFailed = true) => fetchApi('/api/companies', {
    method: 'post',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const updateCompany = useCallback((values:Partial<ICompany>, notifyWhenFailed = true) => fetchApi(`/api/companies/${values.id}`, {
    method: 'put',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const deleteCompany = useCallback((id:string) => fetchApi(`/api/companies/${id}`, {
    method: 'delete',
    notifyWhenFailed: true
  }), []);

  return {
    getCompany,
    getCompanies,
    createCompany,
    updateCompany,
    deleteCompany
  };
};