import { useCallback } from 'react';

import { IDocument } from '%/entities/document';
import { useApi } from '~/core/api';

export const useDocumentsActions = () => {
  const { fetchApi } = useApi();

  const getDocuments = useCallback(() => fetchApi('/api/dictionary/documents', { notifyWhenFailed: true }), []);
  const createDocument = useCallback((values:IDocument, notifyWhenFailed = true) => fetchApi('/api/dictionary/documents', {
    method: 'post',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const updateDocument = useCallback((values:IDocument, notifyWhenFailed = true) => fetchApi(`/api/dictionary/documents/${values.id}`, {
    method: 'put',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const deleteDocument = useCallback((id:string) => fetchApi(`/api/dictionary/documents/${id}`, {
    method: 'delete',
    notifyWhenFailed: true
  }), []);

  return {
    getDocuments,
    createDocument,
    updateDocument,
    deleteDocument
  };
};