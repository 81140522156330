import classNames from 'classnames';
import { useEffect } from 'react';

import { delay } from '~/shared/tools/delay';

import { INotificationProps } from './notification';

import warningIcon from '@css-theme/icons/attention.svg';
import checkIcon from '@css-theme/icons/check-green.svg';
import closeIcon from '@css-theme/icons/delete.svg';

import styles from './notification.module.styl';

export const Notification:React.FC<INotificationProps> = ({ id, text, onClose, autohide, duration = 4, type = 'info' }) => {
  const closeHandler = () => {
    onClose(id);
  };

  const autoCloseHandler = async () => {
    await delay(duration * 1000);
    onClose(id);
  };

  useEffect(() => {
    if (autohide) autoCloseHandler();
  }, [onClose]);

  return (
    <div className={classNames(styles.item, styles[`type-${type}`])}>
      <div className={styles.content}>
        {type === 'success' && <img src={checkIcon}/>}
        {type === 'warning' && <img src={warningIcon}/>}
        { text }
        {autohide && <span className={styles.border} style={{ animationDuration: `${duration}s` }}/>}
      </div>
      {!autohide && <span className={styles.close} onClick={closeHandler}>
        <img src={closeIcon}/>
      </span>}
    </div>
  );
};