import '@css-theme/vars.css';

import { StrictMode } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { getViewAbsoluteUrl, getViewContextUrl } from '~/core/views';
import { NotificationProvider } from '~/shared/components/notification';
import { PrivateRoute } from '~/shared/components/private-route';
import { DictionaryProvider } from '~/shared/hooks/use-dictionary';
import { UserProvider } from '~/shared/hooks/use-user';
// import { ServiceWorker } from '~/shared/tools/service-worker';
import { Employer } from '~/views/employer';
import { Entities } from '~/views/entities';
import { Login } from '~/views/login';
import { Profile } from '~/views/profile';
import { Upload } from '~/views/upload';

import './app.module.styl';

const Application:React.FC = () => {
  const routes = useRoutes([
    { path: getViewContextUrl('login'), element: <Login/> },
    { path: getViewContextUrl('upload'), element: <Upload/> },
    { path: getViewContextUrl('profile'), element: <PrivateRoute><Profile/></PrivateRoute> },
    { path: getViewContextUrl('entities'), element: <PrivateRoute><Entities/></PrivateRoute> },
    { path: getViewContextUrl('employer'), element: <Employer/> },
    { path: '*', element: <Navigate to={getViewAbsoluteUrl('profile')} replace/> }
  ]);

  return (
    <StrictMode>
      <UserProvider>
        <DictionaryProvider>
          <NotificationProvider>
            { routes }
            {/* <ServiceWorker/> */}
          </NotificationProvider>
        </DictionaryProvider>
      </UserProvider>
    </StrictMode>
  );
};
export const App = Application;