import { useNotification } from '../notification';
import { Notification } from './item';
import { INotificationListProps } from './notification.d';

import styles from './notification.module.styl';

export const NotificationList:React.FC<INotificationListProps> = ({ list }) => {
  const { removeNotification } = useNotification();

  return (
    <div className={styles.list}>
      { list.map(el => (
        <Notification key={el.id} {...el} onClose={removeNotification}/>
      )) }
    </div>
  );
};