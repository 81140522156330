import { useEffect, useState } from 'react';

import { delay } from '~/shared/tools/delay';

import { useMounted } from './use-mounted';

const format = (sec:number):string => `${`00${Math.floor(Math.max(0, sec) / 60)}`.slice(-2)}:${`00${Math.max(0, sec) % 60}`.slice(-2)}`;

type TimerProps = {
  timeLeft: number
  formatted: string
  setTimer: (ms:number) => void
}

export const useTimer = (timeout:number):TimerProps => {
  timeout = Math.ceil(timeout / 1000);
  const [timeLeft, setTimeLeft] = useState(timeout);
  const [formatted, setFormatted] = useState(format(timeout));
  const isMounted = useMounted();

  const startTimer = async () => {
    await delay(1000);
    if (!timeLeft || !isMounted()) {
      return;
    }
    const time = timeLeft - 1;
    setTimeLeft(time);
    setFormatted(format(time));
  };

  const setTimer = (ms:number) => {
    const sec = Math.ceil(ms / 1000);
    setTimeLeft(sec);
  };

  useEffect(() => {
    startTimer();
  }, [timeLeft]);

  return {
    timeLeft,
    formatted,
    setTimer
  };
};