import { useCallback, useEffect, useRef } from 'react';

export const useMounted = () => {
  const isMounted = useRef<boolean>(false);
  const get = useCallback(() => isMounted.current, []);

  useEffect(() => {
    isMounted.current = true;

    return () => { isMounted.current = false };
  }, []);

  return get;
};