import { createContext, useContext, useEffect, useState } from 'react';

import { IAuthRequestBody } from '%/entities/auth';
import { IUser } from '%/entities/user';
import { LOGOUT_EVENT_NAME } from '~/shared/components/auth/tools';
import { safeGetItemFromLS } from '~/shared/tools/safe-localstorage';

import { useUserActions } from './use-user-actions';

type IUserContext = {
  user: IUser|null
  updateUser?: (params:Partial<IUser>, merge?: boolean) => void
  logout?: () => void
  login?: (params:IAuthRequestBody) => Promise<any>
}

const defaultContext = {
  user: null
};

export const UserContext = createContext<IUserContext>(defaultContext);
UserContext.displayName = 'UserCtx';

export const UserProvider:React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const store = safeGetItemFromLS('hr-doc:user') as IUser;
  const [user, setUser] = useState(store?.token ? store : null);
  const { getCurrentUser, logout: logoutUser } = useUserActions();

  const updateUser = (data:Partial<IUser>|null, merge = true) => {
    const userNew = Object.assign({}, merge ? user : null, data);
    setUser(userNew);
    window.localStorage.setItem('hr-doc:user', JSON.stringify(userNew));
  };

  const clearUser = () => { updateUser(null, false) };

  const getUserInfo = async () => {
    if (!user?.token) {
      console.warn('get user info: try without token');
      return;
    }
    const userData = await getCurrentUser();

    if (!userData?.id) {
      console.warn('get user info: ', userData?.message || 'error');
      return;
    }
    updateUser({
      ...(userData.id === user.id || !user.id ? user : {}),
      ...userData
    }, false);
  };

  const logout = async () => {
    const { errorStatus } = await logoutUser();
    if (!errorStatus) {
      clearUser();
    }
  };

  useEffect(() => {
    if (user?.token && !user?.id) {
      getUserInfo();
    }
  }, [user?.token]);

  useEffect(() => {
    getUserInfo();
    window.addEventListener(LOGOUT_EVENT_NAME, clearUser);

    return () => {
      window.removeEventListener(LOGOUT_EVENT_NAME, clearUser);
    };
  }, []);

  return (
    <UserContext.Provider value={{
      user,
      updateUser,
      logout
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export const getToken = () => safeGetItemFromLS('hr-doc:user')?.token;