type IView = {
  root: string
  direct?: string
  [name:string]: string | undefined
}

type IViews = {
  [name: string]: IView
}

export const views:IViews = {
  login: {
    root: '/login',
    direct: '1'
  },
  upload: {
    root: '/upload/:id',
    direct: '1'
  },
  profile: {
    root: '/profile',
    personal: 'personal',
    password: 'password'
  },
  employer: {
    root: '/employer',
    offer: 'offers/:id',
    complete: 'offers/:id/complete'
  },
  entities: {
    root: '',
    list: ':entity',
    add: ':entity/new',
    edit: ':entity/edit/:id',
    success: ':entity/complete/:id'
  }
};

export const getViewAbsoluteUrl = (root:string, path?:string):string => views[root] ?
  `${views[root].root}${path && views[root][path] ? `/${views[root][path]}` : ''}` :
  getViewAbsoluteUrl('login');

export const getViewContextUrl = (root:string):string => views[root] ?
  `${views[root].root}${views[root].direct ? '' : '/*'}` :
  getViewContextUrl('login');

export const generatePath = (path:string, params:Record<string, string>):string => {
  for (const param in params) {
    path = path.replace(new RegExp(`(:${param})`), params[param]);
  }
  return path;
};