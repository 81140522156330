import { createContext, useContext, useEffect, useState } from 'react';

import { IDocument } from '%/entities/document';
import { IReport } from '%/entities/report';
import { delay } from '~/shared/tools/delay';

import { useDocumentsActions } from './use-documents-actions';
import { useReportActions } from './use-report-actions';
import { useUser } from './use-user';

type IDictionaryContext = {
  documentsList: IDocument[]
  reloadDocumentsList: () => void
  reportsList: IReport[]
  reloadReportsList: () => void
}

const EMPTY_DOCS:IDocument[] = [];
const EMPTY_REPORTS:IReport[] = [];
const defaultContext:IDictionaryContext = {
  documentsList: EMPTY_DOCS,
  reloadDocumentsList: () => {},
  reportsList: EMPTY_REPORTS,
  reloadReportsList: () => {},
};

export const DictionaryContext = createContext<IDictionaryContext>(defaultContext);
DictionaryContext.displayName = 'DictionaryCtx';

export const DictionaryProvider:React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { user } = useUser();
  const { getDocuments } = useDocumentsActions();
  const { getReports } = useReportActions();

  const [documentsList, setDocumentsList] = useState(EMPTY_DOCS);
  const [reportsList, setReportsList] = useState(EMPTY_REPORTS);

  const getDocumentsList = async () => {
    const resp:any = await getDocuments();
    !resp.message && setDocumentsList(resp || EMPTY_DOCS);

    if (!resp || !resp?.length) {
      await delay(5000);
      getDocumentsList();
    }
  };
  const reloadDocumentsList = () => {
    getDocumentsList();
  };

  const getReportsList = async () => {
    const resp = await getReports();

    if (!resp.errorStatus) {
      setReportsList(resp || EMPTY_REPORTS);
    }
  };
  const reloadReportsList = () => {
    getReportsList();
  };

  useEffect(() => {
    getDocumentsList();
    user?.token && getReportsList();
  }, []);

  return (
    <DictionaryContext.Provider value={{
      reportsList,
      reloadReportsList,
      documentsList,
      reloadDocumentsList
    }}>
      {children}
    </DictionaryContext.Provider>
  );
};

export const useDictionary = () => useContext(DictionaryContext);