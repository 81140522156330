import classNames from 'classnames';

import loader from './loader.png';

import styles from './loader.module.styl';

export const Loader:React.FC<{ className?:string }> = ({ className }) => (
  <div className={classNames(styles.root, className)}>
    <div className={classNames(styles.element, className)}><img src={loader}/></div>
  </div>
);