import { Navigate, useLocation } from 'react-router-dom';

import { UserRoles } from '%/data/user-roles';
import { getViewAbsoluteUrl } from '~/core/views';
import { Loader } from '~/shared/components/loader';
import { useUser } from '~/shared/hooks/use-user';

type IPrivateRouteProps = {
  children?: React.ReactNode
  fallbackUrl?: string,
  roles?: (keyof typeof UserRoles)[]
}

export const PrivateRoute = ({ children, fallbackUrl = getViewAbsoluteUrl('login'), roles = [] }:IPrivateRouteProps):any => {
  const { user } = useUser();
  const location = useLocation();

  return user?.token ?
    user?.role ?
      (!roles.length || roles.includes(user.role)) ?
        children :
        <Navigate to={getViewAbsoluteUrl('profile')} replace/> :
      <Loader/> :
    <Navigate to={fallbackUrl} state={{ from: location }}/>;
};