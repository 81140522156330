import { createContext, useCallback, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { delay } from '~/shared/tools/delay';

import { NotificationList } from './list';
import { INotificationContext, INotificationProps } from './notification';

import styles from './notification.module.styl';

const defaultContext:INotificationContext = {
  addNotification: () => Promise.resolve(''),
  removeNotification: () => {},
  sendingIds: [],
  addSendingId: () => {},
  removeSendingId: () => {}
};

export const NotificationContext = createContext<INotificationContext>(defaultContext);
NotificationContext.displayName = 'NotificationCtx';

export const NotificationProvider:React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const list = useRef<INotificationProps[]>([]);
  const [listCount, setListCount] = useState(0);
  const [sending, setSending] = useState<string[]>([]);

  const addNotification = useCallback(async (item:INotificationProps) => {
    item.id = uuidv4();
    await delay(item.delay || 0);
    setListCount(list.current.push(item));
    return item.id;
  }, []);

  const removeNotification = useCallback((id:string) => {
    list.current = list.current.filter(el => el.id !== id);
      setListCount(list.current.length);
  }, []);

  const addSendingId = (id:string) => {
    setSending([...(new Set([...sending, id]))]);
  };

  const removeSendingId = (id:string) => {
    setSending(sending.filter(sendingId => sendingId !== id));
  };

  return (
    <NotificationContext.Provider value={{
      addNotification,
      removeNotification,
      addSendingId,
      removeSendingId,
      sendingIds: [...sending]
    }}>
      <div className={styles.wrapper}>
        <NotificationList list={list.current} listCount={listCount}/>
      </div>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);