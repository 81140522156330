import { useCallback } from 'react';

import { IDocumentSet } from '%/entities/documents-set';
import { useApi } from '~/core/api';

export const useDocumentsSetActions = () => {
  const { fetchApi } = useApi();

  const getDocumentsSets = useCallback(() => fetchApi('/api/documents-set', { notifyWhenFailed: true }), []);
  const createDocumentsSet = useCallback((values:IDocumentSet, notifyWhenFailed = true) => fetchApi('/api/documents-set', {
    method: 'post',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const updateDocumentsSet = useCallback((values:IDocumentSet, notifyWhenFailed = true) => fetchApi(`/api/documents-set/${values.id}`, {
    method: 'put',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const deleteDocumentsSet = useCallback((id:string) => fetchApi(`/api/documents-set/${id}`, {
    method: 'delete',
    notifyWhenFailed: true
  }), []);

  return {
    getDocumentsSets,
    createDocumentsSet,
    updateDocumentsSet,
    deleteDocumentsSet
  };
};