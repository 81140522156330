import { useApi } from 'core/api';
import { useCallback } from 'react';

import { IReport } from '%/entities/report';

export const useReportActions = () => {
  const { fetchApi } = useApi();

  const getReports = useCallback(() => fetchApi('/api/reports', { notifyWhenFailed: true }), []);
  const getReport = useCallback((id:string) => fetchApi(`/api/reports/${id}`, { notifyWhenFailed: true }), []);
  const createReport = useCallback((values:Partial<IReport>, notifyWhenFailed = true) => fetchApi(`/api/reports`, {
    method: 'post',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const updateReport = useCallback((values:Partial<IReport>, notifyWhenFailed = true) => fetchApi(`/api/reports/${values.id}`, {
    method: 'put',
    json: JSON.stringify(values),
    notifyWhenFailed
  }), []);
  const deleteReport = useCallback((id:string) => fetchApi(`/api/reports/${id}`, {
    method: 'delete',
    notifyWhenFailed: true
  }), []);

  return {
    getReports,
    getReport,
    createReport,
    updateReport,
    deleteReport
  };
};